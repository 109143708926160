import React from 'react';
import { useAppState, MarkupRender, getUploadedImagePath } from '@nutastic/nutastic-react-app'
import PageHeader from '../PageHeader'
import getBackgroundColor from '../../utils/getBackgroundColor'

const CustomPage = () => {
    const {
        page: {
            image_1,
            title,
            content,
            field_10: subtext,
            num_5: header_type,
            field_1_filtered: section_content_1,
            num_1: section_color_1,
            field_2_filtered: section_content_2,
            num_2: section_color_2,
            field_3_filtered: section_content_3,
            num_3: section_color_3
        }
    } = useAppState();

    const renderContentSection = (sectionContent, colorId) => {
        const bgColorClass = getBackgroundColor(colorId);
        return sectionContent ? <section className={`page-section ${bgColorClass} page-section--padded`}>
            <div className="container text-left">
                <div className="paragraph"><MarkupRender content={sectionContent} /></div>
            </div>
        </section> : null
    }

    return <React.Fragment>
        <PageHeader
            image={getUploadedImagePath(image_1)}
            type={header_type}
            title={title}
            subtext={subtext}
        />
        { renderContentSection(content) }
        { renderContentSection(section_content_1, section_color_1) }
        { renderContentSection(section_content_2, section_color_2) }
        { renderContentSection(section_content_3, section_color_3) }
    </React.Fragment>
};

export default CustomPage;
