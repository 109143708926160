
const backgroundColors = {
    1: 'black',
    2: 'olive',
    3: 'nude',
    4: 'light',
    5: 'white'
}
const getBackgroundColor = id => `background-${backgroundColors[id]}` || 'background-white';

export default getBackgroundColor;
