import React, {useState} from 'react';
import {ReactSVG} from "react-svg";
import { getPublicImagePath, ToggleMenu, useAppState } from '@nutastic/nutastic-react-app';
import classnames from "classnames";

const Header = () => {
    const { headerNavItems = [] } = useAppState();
    const [ isMenuOpen, setIsMenuOpen ] = useState(false);
    const componentClass = "header";
    const navigationLinks = headerNavItems.map((n,i) => {
        const { href, title, new_window } = n;
        return <li key={i}><a href={href} target={ parseInt(new_window) ? "_blank" : "_self"}>{ title }</a></li>;
    });

    const menuButtonClasses = classnames(`${componentClass}__menuBtn`,  {
        "ion-ios-keypad": !isMenuOpen,
        "ion-ios-close": isMenuOpen,
        "isOpen": isMenuOpen
    });

    return <header className={ componentClass }>
        <div className="container h-100">
            <a href="/"><ReactSVG className={ `${componentClass}__logo` } src={ getPublicImagePath('logo.svg') } /></a>
        </div>
        <i className={ menuButtonClasses } onClick={() => setIsMenuOpen(!isMenuOpen)} />
        <ToggleMenu isOpen={isMenuOpen} animation="slideFromRight" onOuterClick={() => setIsMenuOpen(false)}>
            <ul>{ navigationLinks }</ul>
        </ToggleMenu>
    </header>;
};

export default Header;
