import React from 'react';
import classnames from 'classnames';
import {getBackgroundImg} from "@nutastic/nutastic-react-app";

const getHeaderClass = type => {
    switch ((type || 0).toString()) {
        case '1': return 'left-layout'
        case '2': return 'right-layout'
        case '3': return 'custom-layout'
        default: return 'basic-layout'
    }
};

const PageHeader = ({ children, className, image, subtext, tagline, taglineLink, title, type }) => {
    const componentClass = 'page-header';
    const classes = classnames(componentClass, className, `${componentClass}--${getHeaderClass(type)}`);
    const headerImgStyle = type && image ? getBackgroundImg(image) : {};
    const useImage = ((type || 0).toString() === '1' || (type || 0).toString() === '2') && image !== "";
    const useChildren = (type || 0).toString() === '3';
    const TagLineComponent = taglineLink ? 'a' : 'div';
    const tagLineProps = taglineLink ? { href: taglineLink } : {};

    return <section className={classes}>
        <div className={ `${componentClass}__container` }>
            { useImage && <div className={`${componentClass}__img`} style={headerImgStyle} /> }
            <div className={`${componentClass}__content`}>
                { tagline && <TagLineComponent className="sub-title" {...tagLineProps}>{ tagline }</TagLineComponent> }
                <h1 className="special-font">{ title }</h1>
                { subtext && <p>{ subtext }</p> }
            </div>
            { useChildren && children }
        </div>
    </section>
};

export default PageHeader;
