import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CustomPage from '../pages/CustomPage';
import Header from '../Header';
import Footer from '../Footer';
import HomePage from '../pages/HomePage';
import PortfolioPage from '../pages/PortfolioPage';
import GalleryPage from '../pages/GalleryPage';
import { AppContainer } from '@nutastic/nutastic-react-app';

const App = () => {
    const renderPageRoute = () => {
        return <Switch>
            <Route path='/' exact={true} component={HomePage}/>
            <Route path='/home' component={HomePage}/>
            <Route path='/portfolio/:slug' component={GalleryPage} />
            <Route path='/portfolio' exact={true} component={PortfolioPage} />
            <Route path='/page/:slug' component={CustomPage}/>
        </Switch>;
    };

    return <AppContainer>
        <Header />
        { renderPageRoute() }
        <Footer />
    </AppContainer>;
};

export default App;
