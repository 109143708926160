import React from 'react';
import { getBackgroundImg, getUploadedImagePath, MarkupRender, useAppState } from '@nutastic/nutastic-react-app';
import PageHeader from '../PageHeader';
import Fade from 'react-reveal/Fade';
import Masonry from 'react-masonry-css';
import Zoom from 'react-medium-image-zoom';
import getBackgroundColor from '../../utils/getBackgroundColor';
import classnames from 'classnames';
import { gridBreakpoints } from '../../utils/constants'

const GalleryPage = () => {
    const componentClass = 'gallery-page';
    const {
        page: {
            title,
            excerpt,
            content: sectionOneContent,
            field_1: sectionOneLayout,
            field_2: sectionOneBackground,
            field_3: sectionTwoContent,
            field_4: sectionTwoLayout,
            field_5: sectionTwoBackground,
            field_6: sectionThreeContent,
            field_7: sectionThreeLayout,
            field_8: sectionThreeBackground,
            field_9: imageSectionOneText,
            field_10: imageSectionTwoText,
            num_4: imageSectionOneLayout,
            num_5: imageSectionTwoLayout,
            image_1,
            image_2,
            image_3,
            image_4,
            image_5
        },
        gallery_1,
        gallery_2,
        gallery_3
    } = useAppState();

    const renderGalleryItems = gallerySubset => {
        return gallerySubset.map( (item,i) => {
            return <Fade key={i}><Zoom>
                <img src={getUploadedImagePath(item.image)} alt={item.name}/>
            </Zoom></Fade>;
        });
    }

    const imageBreakpointsTrips = {
        default: 3,
        [gridBreakpoints.sm]: 1,
        [gridBreakpoints.md]: 1,
        [gridBreakpoints.lg]: 3
    };

    const imageBreakpointsDoubles = {
        default: 4,
        [gridBreakpoints.sm]: 1,
        [gridBreakpoints.md]: 2,
        [gridBreakpoints.lg]: 2
    };

    const imageBreakpointsSmall = {
        default: 2
    };

    const renderGallerySection = (sectionContent, sectionImages, colorId, layoutId) => {
        const layoutKey = parseInt(layoutId);
        const masonryClass = layoutKey > 2 ? "masonry half-section" : "masonry";
        const masonryConfig = layoutKey > 2
            ? imageBreakpointsSmall : sectionImages.length % 2 === 0 && sectionImages.length > 3
                ? imageBreakpointsDoubles : imageBreakpointsTrips;
        const masonryImages = renderGalleryItems(sectionImages);
        const masonryDiv = <Masonry breakpointCols={masonryConfig} className={masonryClass} columnClassName="masonry__column">
            {masonryImages}
        </Masonry>;
        const contentClass = layoutKey > 2 ? "paragraph half-section" : "paragraph";
        const contentDiv = sectionContent ? <MarkupRender content={sectionContent} className={contentClass} /> : null;
        const clearDiv = sectionContent ? <div className="clear xxl"/> : null;
        const divideDiv =  <div className="section-divide"/>;
        const bgColorClass = getBackgroundColor(parseInt(colorId));

        const getLayout = () => {
            if(layoutKey === 1){
                return [ masonryDiv, clearDiv, contentDiv ];
            } else if(layoutKey === 2){
                return [ contentDiv, clearDiv, masonryDiv ];
            } else if(layoutKey === 3){
                return [ masonryDiv, divideDiv, contentDiv ];
            } else if(layoutKey === 4){
                return [ contentDiv, divideDiv, masonryDiv ];
            }
            return null;
        }

        return layoutKey > 0 && layoutKey < 5 ? <section className={`page-section ${bgColorClass} page-section--padded`}>
            <div className="container">
                { getLayout() }
            </div>
        </section> : null
    }

    const renderImageSection = (content, image, layoutId) => {
        const layoutKey = parseInt(layoutId);
        const sectionClasses = classnames('page-section', 'imgSection', {
            'imgSection--leftImage': layoutKey === 1,
            'imgSection--rightImage': layoutKey === 2,
            'imgSection--fullImage' : layoutKey === 3
        })
        return layoutKey > 0 && layoutKey < 4 ? <section className={sectionClasses}>
            <div className="imgSection__img" style={getBackgroundImg(getUploadedImagePath(image))} />
            <div className="imgSection__text">
                <div className="container">
                    {content}
                </div>
            </div>
        </section> : null
    }

    return <React.Fragment>
        <PageHeader
            type={3}
            title={title}
            subtext={excerpt}
            tagline={<span><i className="fa fa-angle-left mr-3" />Portfolio Gallery</span>}
            taglineLink="/portfolio"
        >
            <div className={`${componentClass}__headerImages`}>
                { image_1 && <div className="img1" style={getBackgroundImg(getUploadedImagePath(image_1))} /> }
                { image_2 && <div className="img2" style={getBackgroundImg(getUploadedImagePath(image_2))} /> }
                { image_3 && <div className="img3" style={getBackgroundImg(getUploadedImagePath(image_3))} /> }
            </div>
        </PageHeader>
        <div className={`${componentClass}__story`}>
            { renderGallerySection(sectionOneContent, gallery_1, sectionOneBackground, sectionOneLayout) }
            { renderImageSection(imageSectionOneText, image_4, imageSectionOneLayout) }
            { renderGallerySection(sectionTwoContent, gallery_2, sectionTwoBackground, sectionTwoLayout) }
            { renderImageSection(imageSectionTwoText, image_5, imageSectionTwoLayout) }
            { renderGallerySection(sectionThreeContent, gallery_3, sectionThreeBackground, sectionThreeLayout) }
        </div>
    </React.Fragment>
};

export default GalleryPage;
