import React from 'react';
import classnames from 'classnames';
import { removeHtml } from '@nutastic/nutastic-react-app'

const CardBox = ({ className, content, icon, link, title, useSmallTitle }) => {
    const componentClass = 'card-box';
    const classes = classnames(componentClass, className);
    const TitleComponent = useSmallTitle ? 'h4' : 'h3';

    return <div className={classes}>
        <div className={`${componentClass}__title`}>
            { icon && <i className="ion-md-star" /> }
            <TitleComponent>{ title }</TitleComponent>
        </div>
        <p className={`${componentClass}__content`}>{ removeHtml(content) }</p>
        <div className={`${componentClass}__footer`}>
            { link && <a href={link}><i className="card-box-arrow ion-md-arrow-round-forward" /></a> }
        </div>
    </div>;
};

export default CardBox;
