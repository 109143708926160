import React from 'react';
import { getBackgroundImg, getUploadedImagePath, useAppState } from '@nutastic/nutastic-react-app'
import PageHeader from '../PageHeader'
import CardBox from '../CardBox';

const PortfolioPage = () => {
    const componentClass = 'portfolio-page';
    const {
        galleryItems = [],
        page: {
            title,
            content
        }
    } = useAppState();

    const portfolioListRender = galleryItems.map((item, i) => {
        const { image, excerpt, title, slug } = item;
        return <a className="galleryItem" href={`/portfolio/${slug}`}>
            <div className="galleryItem__img" style={getBackgroundImg(getUploadedImagePath(image))} />
            <CardBox className="galleryItem__explore-box" title={title} content={excerpt} />
        </a>;
    });

    return <React.Fragment>
        <PageHeader type={0} title={title} subtext={content} />
        <section className="page-section--padded background-light pt-0 pb-3">
            <div className={`container ${componentClass}__galleries`}>
                { portfolioListRender }
            </div>
        </section>
    </React.Fragment>
};

export default PortfolioPage;
