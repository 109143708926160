import React, { useState } from 'react'
import Fade from 'react-reveal/Fade';
import {fadeAnimationProps} from "../utils/constants";
import { MarkupRender, useAppState, SubscriptionModal, notifySuccess } from '@nutastic/nutastic-react-app'

const Footer = () => {
    const componentClass = "footer";
    const {
        footerNavOne = [],
        footerNavTwo = [],
        callToAction_buttonText,
        callToAction_hide,
        callToAction_link,
        callToAction_text,
        callToAction_title,
        socialMedia,
        subscription_buttonText
    } = useAppState();
    const copyrightYear = new Date().getFullYear();

    const [ showSubscriptionForm, setShowSubscriptionForm ] = useState(false);
    const onSubscriptionSubmit = () => {
        setShowSubscriptionForm(false);
        notifySuccess('Thank you for subscribing. You have successfully been added to the subscription list.')
    };

    const getNavigationLinks = (footerNavItems) => footerNavItems.map((n,i) => {
        const { href, title, new_window } = n;
        return <a href={href} target={ parseInt(new_window) ? "_blank" : "_self"}>{ title }</a>;
    });

    const socialMediaRender = (socialMedia || []).map((item, i) =>
        <a href={item.url} target="_blank" className="social-link">{ item.type }</a>
    );

    return <footer className={`${componentClass} page-section page-section--bordered page-section--padded`}>
        <div className="container">
            <div className="text-center">
                { !callToAction_hide && <Fade {...fadeAnimationProps}>
                    <div>
                        <h1 className="special-font mb-3">{callToAction_title}</h1>
                        <MarkupRender content={callToAction_text} className="paragraph mt-5 mb-5" />
                        {callToAction_link &&
                        <a href={callToAction_link} className="btn btn-primary mt-3">{callToAction_buttonText}</a>}
                    </div>
                </Fade>
                }

                <div className={`${componentClass}__content`}>
                    <div className={`${componentClass}__links`}>
                        <div className="column">
                            <h5>Follow</h5>
                            { socialMediaRender }
                        </div>
                        <div className="column">
                            <h5>Explore</h5>
                            { getNavigationLinks(footerNavOne) }
                        </div>
                        <div className="column mr-0">
                            <h5>Connect</h5>
                            { getNavigationLinks(footerNavTwo) }
                        </div>
                    </div>
                    <div className={`${componentClass}__info`}>
                        <h4>Amber Chrissy</h4>
                        <p>&copy; { copyrightYear } AmberChrissy.com. All Rights Reserved.</p>
                        { subscription_buttonText && <button
                            className="btn btn-outline-secondary mt-5 btn-small"
                            onClick={ () => setShowSubscriptionForm(true) }>
                            { subscription_buttonText }
                        </button> }
                    </div>
                </div>
            </div>
        </div>
        <SubscriptionModal
            title="Subscribe to AmberChrissy.com"
            onSubmit={ onSubscriptionSubmit }
            onClose={ () => setShowSubscriptionForm(false) }
            show={ showSubscriptionForm }
            timeout={ 0 }
        />
    </footer>;
};

export default Footer;
