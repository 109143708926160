import React, { Fragment, useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import classnames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import Fade from 'react-reveal/Fade';
import SwiperCore, { Navigation, EffectFade, Autoplay } from 'swiper';
import CardBox from '../CardBox.jsx';
import { fadeAnimationProps, gridBreakpoints } from '../../utils/constants';
import {
    getBackgroundImg,
    getPublicImagePath,
    getUploadedImagePath,
    removeHtml,
    useAppState,
    useBreakpoints
} from '@nutastic/nutastic-react-app'

SwiperCore.use([Navigation, Autoplay, EffectFade]);

const HomePage = () => {
    const {
        slides,
        exploreItems,
        portfolioItems,
        testimonyItems,
        page: {
            content: welcomeMessage,
            field_1: welcomeTitle,
            field_2: exploreTitle,
            field_3: exploreSubtext,
            field_4: portfolioTitle,
            field_5: portfolioSubtext,
            field_6: testimonyTitle,
            field_7: portfolioButtonText,
            field_9: buttonText,
            field_10: buttonLink,
            num_3,
            num_4,
            num_5
        }
    } = useAppState();
    const breakpoints = useBreakpoints();
    const componentClass = 'home-page';
    const hideExploreSection = parseInt(num_3);
    const hidePortfolioSection = parseInt(num_4);
    const hideTestimonySection = parseInt(num_5);
    const [isAnimating, setIsAnimating] = useState(true);
    const [showBannerImg, setShowBannerImg] = useState(slides.length > 0);
    const showWelcomeButton = buttonLink && buttonText;
    const bannerClasses = classnames(`${componentClass}__hero-banner`, {
       isAnimating
    });

    const swiperProps = {
        autoplay: { delay: 5000 },
        loop: true,
        slidesPerView: 1,
        effect: 'fade',
        fadeEffect: { crossFade: true }
    };
    const slideshowRender = slides.map((slide, i) => <SwiperSlide key={i}>
        <img
            src={ getPublicImagePath('blank.png') }
            style={ getBackgroundImg(getUploadedImagePath(slide.image)) }
            alt={slide.name}
        />
    </SwiperSlide> );

    const exploreBoxesRender = (exploreItems || []).map((item, i) => <CardBox
        className={`${componentClass}__explore-box`}
        content={ item.text }
        key={i}
        title={ item.name }
        link={ item.link }
        useSmallTitle={ true }
    /> );

    useEffect(() => {
        setTimeout(() => setIsAnimating(false), 1750);
        setTimeout(() => setShowBannerImg(false), 3500);
    }, []);

    const renderPortfolioPreview = () => {
        const portfolioUrl = '/portfolio';
        const features = (portfolioItems || []).map((feature, index) => <Fade>
            <a href={portfolioUrl} className="feature-box" id={`box--${index}`} key={index}>
                <img src={ getPublicImagePath('blank.png') } alt="" style={ getBackgroundImg(getUploadedImagePath(feature.image))} />
            </a>
        </Fade>);
        // insert a boxes into the second column for large desktops
        features.splice(breakpoints.lg ? 1 : 0, 0, <div key="c2" className="title-box">
            <h1 className="special-font">{ portfolioTitle }</h1>
            <p>{ portfolioSubtext }</p>
            <a className="btn btn-outline-primary" href={portfolioUrl}>{ portfolioButtonText }</a>
        </div>)
        if(features.length >= 7 && breakpoints.lg){
            features.splice(7, 0, <div key="c1" className="empty-box"/>);
        }
        return features;
    };

    const testimonySlidesRender = (testimonyItems || []).map((item, i) => <SwiperSlide>{ removeHtml(item.text) }</SwiperSlide> );

    return <Fragment>
        <div className={ bannerClasses }>
            <div className='container h-100'>
                { showBannerImg && <img
                    className="hero-img"
                    src={ getPublicImagePath('blank.png') }
                    style={ getBackgroundImg(getUploadedImagePath(slides[0].image)) }
                    alt='Welcome to Amber Chrissy Photography'
                /> }
                { showBannerImg && <h1 className="hero-title special-font">{ welcomeTitle }</h1> }
                <Swiper { ...swiperProps } auotplay={{ delay: 7000 }}>{ slideshowRender }</Swiper>
                <div className='hero-text'>
                    <h1 className="special-font">{ welcomeTitle }</h1>
                    <p>{ welcomeMessage }</p>
                    { showWelcomeButton && <a className="btn btn-primary" href={buttonLink}>{ buttonText }</a> }
                </div>
            </div>
        </div>
        { !hideExploreSection &&
        <section
            className={`page-section page-section--bordered page-section--padded ${componentClass}__explore-section`}>
            <div className={`${componentClass}__explore-background`}/>
            <div className="container">
                <h1 className="special-font">{exploreTitle} <span>{exploreSubtext}</span></h1>
                <Fade {...fadeAnimationProps}>
                    <div className={`${componentClass}__explore-boxes`}>{exploreBoxesRender}</div>
                </Fade>
            </div>
        </section>
        }
        { !hidePortfolioSection &&
        <section className="page-section page-section--bordered page-section--padded">
            <div className="container">
                <Masonry
                    breakpointCols={{
                        default: 3,
                        [gridBreakpoints.sm]: 1,
                        [gridBreakpoints.md]: 2,
                        [gridBreakpoints.lg]: 2
                    }}
                    className="masonry"
                    columnClassName="masonry__column"
                >
                    {renderPortfolioPreview()}
                </Masonry>
            </div>
        </section>
        }
        { !hideTestimonySection &&
        <section className="page-section testimony-swiper">
            <Fade {...fadeAnimationProps}>
                <div className="container">
                    <i className="ion-ios-quote testimony-swiper__icon"/>
                    <h3 className="text-center">{testimonyTitle}</h3>
                    <div className="testimony-swiper__nav">
                        <i className="ion-ios-arrow-dropleft-circle testimony-swiper-nav__prev"/>
                        <i className="ion-ios-arrow-dropright-circle testimony-swiper-nav__next"/>
                    </div>
                    <Swiper navigation={{
                        nextEl: '.testimony-swiper-nav__next',
                        prevEl: '.testimony-swiper-nav__prev'
                    }} {...swiperProps}>
                        {testimonySlidesRender}
                    </Swiper>
                </div>
            </Fade>
        </section>
        }
    </Fragment>;
};

export default HomePage;
